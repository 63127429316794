import { Component, OnInit } from '@angular/core';
import { CommonService } from '../../../services/common.service';
import { Router } from '@angular/router';

@Component({
  selector: 'app-issues-info',
  templateUrl: './issues-info.page.html',
  styleUrls: ['./issues-info.page.scss'],
})
export class IssuesInfoPage implements OnInit {

  public errorMessages;
  public dirList = [];
  private Lst;
  private new_file_selected2Upload = false;

  constructor(public commonService: CommonService, private router: Router) { }

  ngOnInit() {
    window.addEventListener("keyup", disableF5);
    window.addEventListener("keydown", disableF5);
                function disableF5(e) {
                  // if ((e.which || e.keyCode) == 116) e.preventDefault();
                  if ((e.which || e.keyCode) == 116){ e.preventDefault();   };
              };
  }

  ionViewDidEnter() { //ngAfterViewInit(){
    // if (this.commonService.valid_user!=null){

    // } else {
    //   // this.router.navigate(['/home']);
    //   this.router.navigate(['/']);
    // }

    // NGON INIT ------------------------------------------------
    window.addEventListener("keyup", disableF5);
    window.addEventListener("keydown", disableF5);

  function disableF5(e) {
      // if ((e.which || e.keyCode) == 116) e.preventDefault();
      if ((e.which || e.keyCode) == 116){ e.preventDefault();   };
  };
// this.commonService.showErrorBlock="none";
//   this.commonService.showSuccessBlock="block";

this.commonService.fup_error_msgs_text = "";

if ( this.commonService.fileUploadResponse!=null &&
this.commonService.fileUploadResponse!="" &&
this.commonService.fileUploadResponse.errorMessages!=null &&
this.commonService.fileUploadResponse.errorMessages!="" &&
this.commonService.fileUploadResponse.errorMessages.length >0 ){
this.errorMessages=this.commonService.fileUploadResponse.errorMessages;
for (let i = 0; i < this.errorMessages.length; i++) {
  if ( i == 0 ){
    this.commonService.fup_error_msgs_text = this.errorMessages[i];
  } else {
    this.commonService.fup_error_msgs_text = this.commonService.fup_error_msgs_text +", "+ this.errorMessages[i];
  }
}
} else {
this.errorMessages=null;
this.commonService.fup_error_msgs_text = "";
}
// SCLTD // console.log("list page:");
// SCLTD // console.log(this.commonService.fileUploadResponse);
if ( this.commonService.fileUploadResponse!=null &&
this.commonService.fileUploadResponse!="" &&
this.commonService.fileUploadResponse.directoryList!=null &&
this.commonService.fileUploadResponse.directoryList!="" &&
this.commonService.fileUploadResponse.directoryList.length >0 ){
//this.dirList=this.commonService.fileUploadResponse.directoryList;
// console.log('1');
for(let i in this.commonService.fileUploadResponse.directoryList) {
// console.log('2');
  if ( this.commonService.fileUploadResponse.directoryList[i].files !=null &&
        this.commonService.fileUploadResponse.directoryList[i].files.length>0 ){
// console.log("3");
          let af = this.commonService.fileUploadResponse.directoryList[i].files;
          let name = this.commonService.fileUploadResponse.directoryList[i].name;
          let type = this.commonService.fileUploadResponse.directoryList[i].type;
          if (name !=null && name.length>0){

          }else {
            name="";
          }
          if (type !=null && type.length>0){

          }else {
            type="";
          }
// console.log("4");
          // if ( af!=null && af.length>0 ){
            for(let j in af) {
// console.log("5");
              this.dirList.push({
                "name" : name,
                "type"  : type,
                "fname"       : ( (af[j].name!=null && af[j].name.length>0)?af[j].name:"") ,
                "ftype"       : ( (af[j].type!=null && af[j].type.length>0)?af[j].type:"") ,
                "modifiedDate"       : ( (af[j].fileDetails!=null && af[j].fileDetails.modifiedDate!=null )?af[j].fileDetails.modifiedDate:""),
                "fileSize"       : ( (af[j].fileDetails!=null && af[j].fileDetails.fileSize!=null)?af[j].fileDetails.fileSize:"" )
              });
            } // for j
          // }
  }
} // for i
// SCLTD // console.log(this.dirList);
} else {
this.dirList=null;
}
    // NGON INIT ------------------------------------------------
  }

  close_clicked(){
    this.commonService.ClosePopUpModal();
  }
  upload_NF_clicked(){
    this.commonService.reset_glb_variables();

    this.commonService.ClosePopUpModal();
    this.router.navigate(['/custom-quote/upload-file']);
  }

}
