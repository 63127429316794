export class Configuration {

  // ---------------------------------------------------------------------------------------
  // URLS FOR STAGE SERVER / STAGE END POINTS
  // ---------------------------------------------------------------------------------------
  public static BaseAuthApiUrl: string =   "https://api.protoexpress-api.net/dev/token";
  public static BaseAuthApiUrl_Authorization: string =   "Basic 037c5c10-916b-11e8-a9a3-6710a465c191";
  public static BaseAppProtoexpressUrl: string =   "https://stagingkicad6.app.protoexpress.com/superAPI";
  public static BaseSQSUrl: string = "https://sqs.us-east-1.amazonaws.com/719945103823/sccdevd80252d6-69ea-4937-9619-6a626d1c4f18";
  public static BaseAppProtoexpressUrl_pcb_front_image: string =   "https://stagingkicad6.app.protoexpress.com/superAPI";
  public static BaseAppProtoexpressUrl_pcb_bottom_image: string =   "https://stagingkicad6.app.protoexpress.com/superAPI";
  public static BaseApiProtoexpressUrl: string =    "https://api.protoexpress-api.net/dev/account/profile";
  public static FS_BaseApiUrl: string =   "https://staging.protoexpress.com/sierra-app/oauth/token";
  public static FS_BaseApiUrl_Authorization: string =  "Basic c2llcnJhLWNsaWVudDpzaWVycmEtc2VjcmV0";
  public static FS_BaseApiUrl_username: string =   "custom-quote-tools";
  public static FS_BaseApiUrl_password: string =   "DE70B9F7657F9822EE8F7DB1EC80C7DD";
  public static FS_BaseApiUrl_grant_type: string =  "password";
  public static FS_CusQuoApiUrl: string = "https://staging.protoexpress.com/sierra-app/femscreaterequest";
  public static FS_SubCustOrderApiUrl: string = "https://stagingkicad6.app.protoexpress.com/SubCustomOrd";
  public static FS_femsgetpdlistUrl: string = "https://staging.protoexpress.com/sierra-app/femsgetpdlist";
  public static FS_femscreateprepartdataUrl: string = "https://staging.protoexpress.com/sierra-app/femscreateprepartdata";
  public static dataDecryptUrl: string = "https://api.protoexpress-api.net/cqstage/decryption";
  public static femsGetUserProfileUrl: string = "https://staging.protoexpress.com/sierra-app/account/profile";
  public static ssoRedirectionUrl: string = "https://staging.protoexpress.com/customer-portal/chklogin.jsp?src=https://stagingcustomquote.app.protoexpress.com/sso-response";
  public static logoClickUrl: string = "https://staging.protoexpress.com";
  public static logoutClickUrl: string = "https://staging.protoexpress.com/pcb/";



}
