import { NgModule } from '@angular/core';
import { PreloadAllModules, RouterModule, Routes } from '@angular/router';

const routes: Routes = [
  {
    path: 'home-internal',
    loadChildren: () => import('./home/home.module').then( m => m.HomePageModule)
  },
  {
    path: '',
    redirectTo: 'sso-login',
    pathMatch: 'full'
  },
  // {
  //   path: '',
  //   redirectTo: 'home',
  //   pathMatch: 'full'
  // },
  // {
  //   path: '',
  //   loadChildren: () => import('./home/home.module').then( m => m.HomePageModule)
  // },
  {
    path: 'custom-quote/upload-file',
    loadChildren: () => import('./pages/custom-quote/upload-file/upload-file.module').then( m => m.UploadFilePageModule)
  },
  {
    path: 'custom-quote/pcb-details',
    loadChildren: () => import('./pages/custom-quote/pcb-details/pcb-details.module').then( m => m.PcbDetailsPageModule)
  },
  {
    path: 'custom-quote/quote-details',
    loadChildren: () => import('./pages/custom-quote/quote-details/quote-details.module').then( m => m.QuoteDetailsPageModule)
  },
  {
    path: 'custom-quote/quote-summary',
    loadChildren: () => import('./pages/custom-quote/quote-summary/quote-summary.module').then( m => m.QuoteSummaryPageModule)
  },
  {
    path: 'file-upload-error',
    loadChildren: () => import('./pages/modals/file-upload-error/file-upload-error.module').then( m => m.FileUploadErrorPageModule)
  },
  {
    path: 'custom-quote/issues-conf-step1',
    loadChildren: () => import('./pages/custom-quote/issues-conf-step1/issues-conf-step1.module').then( m => m.IssuesConfStep1PageModule)
  },
  {
    path: 'sso-redirect',
    loadChildren: () => import('./signin/sso-redirect/sso-redirect.module').then( m => m.SsoRedirectPageModule)
  },
  {
    path: 'final-submit-success',
    loadChildren: () => import('./pages/modals/final-submit-success/final-submit-success.module').then( m => m.FinalSubmitSuccessPageModule)
  },
  {
    path: 'final-submit-error',
    loadChildren: () => import('./pages/modals/final-submit-error/final-submit-error.module').then( m => m.FinalSubmitErrorPageModule)
  },
  {
    path: 'sso-login',
    loadChildren: () => import('./signin/sso-login/sso-login.module').then( m => m.SsoLoginPageModule)
  },
  {
    path: 'sso-response',
    loadChildren: () => import('./signin/sso-response/sso-response.module').then( m => m.SsoResponsePageModule)
  },  {
    path: 'issues-info',
    loadChildren: () => import('./pages/modals/issues-info/issues-info.module').then( m => m.IssuesInfoPageModule)
  },
  {
    path: 'file-upload-block-user',
    loadChildren: () => import('./pages/modals/file-upload-block-user/file-upload-block-user.module').then( m => m.FileUploadBlockUserPageModule)
  },


];

@NgModule({
  imports: [
    RouterModule.forRoot(routes, { preloadingStrategy: PreloadAllModules })
  ],
  exports: [RouterModule]
})
export class AppRoutingModule { }
