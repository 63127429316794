import { Component, OnInit } from '@angular/core';
import { CommonService } from '../../../services/common.service';

@Component({
  selector: 'app-final-submit-error',
  templateUrl: './final-submit-error.page.html',
  styleUrls: ['./final-submit-error.page.scss'],
})
export class FinalSubmitErrorPage implements OnInit {
  public user_details;
  constructor(public commonService: CommonService) { }

  ngOnInit() {
              window.addEventListener("keyup", disableF5);
              window.addEventListener("keydown", disableF5);

            function disableF5(e) {
                // if ((e.which || e.keyCode) == 116) e.preventDefault();
                if ((e.which || e.keyCode) == 116){ e.preventDefault();   };
            };

    this.user_details = this.commonService.valid_user_details;
  }

  closeDialog(){
    this.commonService.ClosePopUpModal();
  }

}
