import { Component, OnInit } from '@angular/core';
import { CommonService } from '../../../services/common.service';
import { Router } from '@angular/router';
//import { NavController , Platform} from 'ionic-angular';
import{ Configuration } from '../../../config/configuration';

@Component({
  selector: 'app-final-submit-success',
  templateUrl: './final-submit-success.page.html',
  styleUrls: ['./final-submit-success.page.scss'],
})
export class FinalSubmitSuccessPage implements OnInit {
  public user_details;
  public log_out_URL;
  constructor(public commonService: CommonService, private router: Router) { }

  ngOnInit() {
              window.addEventListener("keyup", disableF5);
              window.addEventListener("keydown", disableF5);

            function disableF5(e) {
                // if ((e.which || e.keyCode) == 116) e.preventDefault();
                if ((e.which || e.keyCode) == 116){ e.preventDefault();   };
            };

    this.user_details = this.commonService.valid_user_details;
    this.log_out_URL = Configuration.logoutClickUrl;
  }

  closeDialog(){
    this.commonService.ClosePopUpModal();
  }

  submit_anoth_dsg_pack(){
    this.commonService.reset_glb_variables();

    //this.router.navigate(['/custom-quote/upload-file']);
    this.router.navigateByUrl('/custom-quote/upload-file');
    this.commonService.ClosePopUpModal();
  }
  logout_mve2_sierra_home(){
    this.commonService.ClosePopUpModal();
    // this.router.navigate(['http://www.google.com']);
    this.commonService.ShowLoadingOverlay("Please Wait...");
    //window.open('https://www.protoexpress.com/pcb/', '_self');
    window.open(  this.log_out_URL  , '_self');
  }
}
