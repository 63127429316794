/* eslint-disable max-len */
/* eslint-disable no-trailing-spaces */
/* eslint-disable quote-props */
/* eslint-disable object-shorthand */
/* eslint-disable @typescript-eslint/quotes */
/* eslint-disable no-var */
/* eslint-disable @typescript-eslint/no-inferrable-types */
/* eslint-disable @typescript-eslint/naming-convention */
import { Injectable } from '@angular/core';
import {HttpClient, HttpErrorResponse , HttpHeaders ,HttpParams } from '@angular/common/http';
import { Observable } from "rxjs";
import {  throwError } from 'rxjs';
import { retry, catchError } from 'rxjs/operators';
import { ThrowStmt } from '@angular/compiler';
import { Router } from '@angular/router';
import { LoadingController } from '@ionic/angular';

import {ModalController} from '@ionic/angular';
import {FileUploadErrorPage} from '../pages/modals/file-upload-error/file-upload-error.page';
import {FileUploadBlockUserPage} from '../pages/modals/file-upload-block-user/file-upload-block-user.page';
import {IssuesInfoPage}  from '../pages/modals/issues-info/issues-info.page';
import {FinalSubmitSuccessPage} from '../pages/modals/final-submit-success/final-submit-success.page';
import {FinalSubmitErrorPage} from '../pages/modals/final-submit-error/final-submit-error.page';

import { DomSanitizer, SafeResourceUrl, SafeUrl} from '@angular/platform-browser';
// import * as $ from 'jquery';
import{ Configuration } from '../config/configuration';

@Injectable({
  providedIn: 'root'
})
export class CommonService {

  // SSO
  public ssoResponseEncrypt="";
  public ssoResponseDecrypt:any=null;
  public sso_UserId = "";
  public sso_error = false;
  // SSO

  //public valid_user:string;
  public valid_user;
  public valid_user_details;
  public file2Upload: File;
  public fileUploadResponse;
  public usr_quote_type_selected="";
  public invalid_login = false;
  public reset_variables_check = false;

  private loading = null;
  public showErrorBlock = "";
  public showSuccessBlock = "";

  public fupResponse_block_user_flag="";
  public fupResponse_block_user_msgs="";

  // form variables
  public fup_error_msgs_text = "";
  public frm_qsummary_contact_email_id ="";
  public frm_Design_Package_Name ="";
  public frm_part_no ="";
  public frm_revision ="";
  public frm_mil_spec="Not Sure";
  public frm_itar="Not Sure";
  public frm_n_layers ="1";
  public frm_board_dim_x="";
  public frm_board_dim_y="";
  public frm_board_thickness="";
  public frm_surface_finish="";
  public frm_board_material="";
  public frm_bom_file_name="";

  public frm_noof_boards=1;
  public frm_noof_assemblies=1;
  public frm_noof_bare_boards=0;
  public frm_turn_time_days="";
  public frm_turn_time_days_assmb = "";
  public frm_include_electrical_testing = true;
  public frm_include_assembly_testing = true;

  public pcb_front_image_data = "";
  public pcb_bottom_image_data = "";
  public pcb_front_image_data_img = null;
  public pcb_bottom_image_data_img = null;

  public tr_error_blk_clk_org_page = "";
  public tr_error_blk_clk_count = 0;

  public frm_gen_notes_4_SierrTeam="";
  public frm_ics1p_corr_answer=""; //Upload New package to resolve issues

  public frm_submt_dsgPack_withErrors_Confirm = false;
  public frm_additional_notes="";
  // public frm_tot_no_of_components="";
  // public frm_do_not_install="";

  public uploading_File_from="";
  public accpt_packge_with_issues_Bclicked=false;
  public accpt_new_packge_aftr_fupload_Bclicked=false;
  // form variables

  // // +++++++++++++++++++++++++++++++++++++++++++++++
  // FS_BaseApiUrl: string =   "https://staging.protoexpress.com/sierra-app";
  // FS_CusQuoApiUrl: string = "https://staging.protoexpress.com/sierra-app";
  // FS_SubCustOrderApiUrl: string = "https://staging.app.protoexpress.com";
  // FS_femsgetpdlistUrl: string = "https://staging.protoexpress.com/sierra-app";
  // FS_femscreateprepartdataUrl: string = "https://staging.protoexpress.com/sierra-app";

  public Access_token_4_FEMS;

  public FS_Step1_resp;
  public FS_Step2_resp;
  public FS_Step3_resp;
  public FS_Step4_resp;
  public FS_Step5_resp="";
  // +++++++++++++++++++++++++++++++++++++++++++++++

  constructor(private modelController: ModalController, private httpClient: HttpClient,  private router: Router
              , private loadingCtrl: LoadingController , private sanitizer: DomSanitizer) { }

  ShowLoadingOverlay(msg){
    //this.loading = null;
    this.loadingCtrl.create({
      spinner:"crescent",
      message: msg,
      cssClass:'loader-css-class'
    }).then((overlay)=>{
      this.loading = overlay;
      this.loading.present();
    });
  }

  HideLoadingOverlay(){
    if (this.loading == null || this.loading == undefined
        || this.loading == "" ||  this.loading.lenght == 0){
      //alert("  empty");
    }else {
      //alert("not empty");
      this.loading.dismiss();
    }
  }

  getStringDecrypted(){
    this.sso_error = false;
    //*//this.ShowLoadingOverlay("Loading...");
    if ( this.ssoResponseEncrypt!="" && this.ssoResponseEncrypt!=undefined
        && this.ssoResponseEncrypt!=null && this.ssoResponseEncrypt.length>0 ){

          let params1  = new HttpParams().set("encryptedText", this.ssoResponseEncrypt);

          this.httpClient.get<any>( Configuration.dataDecryptUrl
            , { params: params1 }
            ).subscribe((data: any[])=>{
              // this.valid_user_details = data;
              // console.log(this.valid_user_details);
              // this.router.navigate(['/custom-quote/upload-file']);
              //*//this.HideLoadingOverlay();

              // console.log(data);
              this.ssoResponseDecrypt = data;
              // console.log( this.ssoResponseDecrypt );
              // console.log( this.ssoResponseDecrypt.decryptedText );
              if ( this.ssoResponseDecrypt!=null   ){
                      let ssou = this.ssoResponseDecrypt.decryptedText;
                      // SCLTD // console.log(ssou);
                      let ssouSplits = ssou.split("=");
                      if ( ssouSplits[1]!=null && ssouSplits[1]!=""
                            && ssouSplits[1].length>0){
                              this.sso_UserId = ssouSplits[1];
                              // SCLTD // console.log("user id:   ");
                              // SCLTD // console.log( this.sso_UserId );
                              //this.GetLoggedInUserDetails_From_FEMS();
                              this.Get_Access_token_4_FEMS();
                      } else {
                        this.sso_UserId = "";
                        this.sso_error = true;
                        // this.HideLoadingOverlay();
                      }
              } else {
                this.sso_error = true;
                // this.HideLoadingOverlay();
              }

              // this.HideLoadingOverlay();

            }, (error: any[])=>{
              // console.log(error);
              // this.valid_user=null;
              // this.valid_user_details = null;
              // this.HideLoadingOverlay();
              // this.invalid_login = true;

              this.sso_error = true;
              //*//this.HideLoadingOverlay();
              // SCLTD // console.log(error);
            });
    } else {
      // this.HideLoadingOverlay();
      this.sso_error = true;
      //*//this.HideLoadingOverlay();
    }

  }

  Get_Access_token_4_FEMS(){
    this.ShowLoadingOverlay("Validating...");

    var headers = new HttpHeaders().set("Authorization", Configuration.FS_BaseApiUrl_Authorization )
    .set("Content-Type", "application/x-www-form-urlencoded" )
    .set("Accept", "application/json" )
    .set("Access-Control-Allow-Origin", "*" )
    .set("Access-Control-Allow-Methods", "GET" )
    .set("Access-Control-Allow-Methods", "PUT" )
    .set("Access-Control-Allow-Methods", "POST" )
    .set("Access-Control-Allow-Methods", "DELETE" )
    .set("Access-Control-Allow-Methods", "OPTIONS" )
    .set("Access-Control-Allow-Methods", "POST" )
    .set("Access-Control-Allow-Headers", "X-Requested-With" );
const body = new HttpParams()
.set('username', Configuration.FS_BaseApiUrl_username)
.set('password', Configuration.FS_BaseApiUrl_password)
.set('grant_type', Configuration.FS_BaseApiUrl_grant_type);
return this.httpClient.post<any>( Configuration.FS_BaseApiUrl
                    , body
                    , {headers}
                    ).subscribe((data)=>{

                        this.HideLoadingOverlay();

                        this.Access_token_4_FEMS=data;
                        // SCLTD // console.log("Get_Access_token_4_FEMS:");
                        // SCLTD // console.log( this.Access_token_4_FEMS);

                        this.valid_user = data; // map to ooo vars

                        this.GetLoggedInUserDetails_From_FEMS();


                        //return(true);

                    }, (error: any[])=>{

                        // SCLTD // console.log(error);
                        this.HideLoadingOverlay();

                        // this.modelController.create({component:FinalSubmitErrorPage,backdropDismiss:false,cssClass: 'FinalSubmitErrorPage-modal'}).then((modalElement)=>{
                        //   modalElement.present();
                        // });
                        this.sso_error = true;
                    });
  }

  GetLoggedInUserDetails_From_FEMS(){
    this.ShowLoadingOverlay("Fetching User Details...");

    var headers  = new  HttpHeaders().set("Authorization", "Bearer "+this.Access_token_4_FEMS.access_token)
                      .set("Content-Type", "application/json" )
                      .set("Accept", "application/json" );

    let params  = new HttpParams().set("userID", this.sso_UserId);

    //var options = { headers: headers, params: params};

    this.httpClient.get<any>( Configuration.femsGetUserProfileUrl
      , {headers: headers , params: params }
      ).subscribe((data: any[])=>{
        this.valid_user_details = data;
        // SCLTD // console.log(this.valid_user_details);
        this.HideLoadingOverlay();
       this.router.navigate(['/custom-quote/upload-file']);


      }, (error: any[])=>{
        // SCLTD // console.log(error);
        this.valid_user=null;
        this.valid_user_details = null;
        this.HideLoadingOverlay();
        this.invalid_login = true;
        this.sso_error = true;
      });

  }

  ValidateUserAndGetToken(client_id,username,password){
    this.ShowLoadingOverlay("Authenticating...");

    var headers = new  HttpHeaders().set("Authorization", Configuration.BaseAuthApiUrl_Authorization)
                      .set("Content-Type", "application/json" )
                      .set("Accept", "application/json" );

    var postData = {"client_id": client_id, "username": username, "password": password };

    var options = { headers: headers};
    this.httpClient.post<any>( Configuration.BaseAuthApiUrl , postData, options
                    ).subscribe(data => {
                      this.invalid_login = false;
                      // SCLTD // console.log(data);
                      this.valid_user = data;
                      this.HideLoadingOverlay();
                      this.GetLoggedInUserDetails();
                      //this.router.navigate(['/custom-quote/upload-file']);
                      //this.HideLoadingOverlay();
                      //return { redirect: '/login' };
                     }, error => {
                      // SCLTD // console.log(error);
                      this.valid_user=null;
                      this.valid_user_details = null;
                      this.HideLoadingOverlay();
                      this.invalid_login = true;
                    });
  }

  CustomQuoteFileUpload(){
    // alert("from common service");
    // console.log("user:   " +this.valid_user);
    this.ShowLoadingOverlay("Uploading & Validating File...");

    //var formjson:string = "{  'serviceType': 'Fabrication,Assembly', 'customerId': 'renjith345', 'emailAddress': 'ssdc_renthitr@protoexpress.ocm', 'rnumber':'RNUM2'	 }";
    //var formjson: any = { "serviceType": this.usr_quote_type_selected , "customerId": this.valid_user.user_id , "emailAddress": this.valid_user_details.registrationEmail , "rnumber":"RNUM2"	};

    var new_rnum = "";
    new_rnum = "RNUM"+Date.now();

    //var formjson: any = { "serviceType": this.usr_quote_type_selected , "customerId": this.sso_UserId , "emailAddress": this.valid_user_details.registrationEmail , "rnumber":"RNUM2" , "source":"custom_quote"	};
    var formjson: any = { "serviceType": this.usr_quote_type_selected , "customerId": this.sso_UserId , "emailAddress": this.valid_user_details.registrationEmail , "rnumber":new_rnum , "source":"custom_quote"	};

    let formData = new FormData();
    formData.append("file",this.file2Upload,this.file2Upload.name);
    formData.append("data", JSON.stringify( formjson ) );

    var headers = new  HttpHeaders().set("Authorization", this.valid_user.access_token)
                      .set("user_id", this.sso_UserId )
                      .set("Accept", "*/*" );
                      //.set("Content-Type", "multipart/form-data" )
//this.valid_user.user_id
    let params = new HttpParams().set("service", "fileUpload");

    var options = { headers: headers, params: params};
    this.httpClient.post<any>( Configuration.BaseAppProtoexpressUrl , formData, options
                    ).subscribe(data => {

                      this.fileUploadResponse=data;

                      this.fupResponse_block_user_flag="";
                      this.fupResponse_block_user_msgs="";

// BLOCK USER
//  console.log(this.fileUploadResponse);
if ( this.fileUploadResponse!=null && this.fileUploadResponse.blockUserFlag!=null
  && this.fileUploadResponse.blockUserFlag!=""
  && this.fileUploadResponse.blockUserFlag == true ){
    // alert("BLOCK USER");

    this.fupResponse_block_user_flag=this.fileUploadResponse.blockUserFlag;
    if ( this.fileUploadResponse!=null &&
      this.fileUploadResponse!="" &&
      this.fileUploadResponse.blockUserMsg!=null &&
      this.fileUploadResponse.blockUserMsg!="" &&
      this.fileUploadResponse.blockUserMsg.length >0 ){
                this.fupResponse_block_user_msgs=this.fileUploadResponse.blockUserMsg;
    } else {
      this.fupResponse_block_user_msgs="";
    }

    //  console.log( this.fupResponse_block_user_flag );
    //  console.log( this.fupResponse_block_user_msgs );

    this.fileUploadResponse = null;
    this.populate_vars_after_fileUpload();

    this.HideLoadingOverlay();
    this.modelController.create({component:FileUploadBlockUserPage,backdropDismiss:false,cssClass: 'fileuploadBlockUser-modal'}).then((modalElement)=>{
      modalElement.present();
    });
    return;
} else {
  // alert("DONT BLOCK USER");
}
// BLOCK USER

if ( this.fileUploadResponse!=null && this.fileUploadResponse.directoryList!=null
  && this.fileUploadResponse.directoryList!=""
  && this.fileUploadResponse.directoryList.length >0 ){
    // valid response
                // SCLTD // console.log(this.fileUploadResponse);
                        this.populate_vars_after_fileUpload();
                        this.HideLoadingOverlay();
                        //return(true);
                        // this.router.navigate(['/custom-quote/pcb-details']);
                        this.router.navigate(['/custom-quote/issues-conf-step1']);
} else {
  // issues in reponse
  this.fileUploadResponse = null;
  this.populate_vars_after_fileUpload();
  // SCLTD // console.log("issues in reponse");
  // alert("error");
  this.HideLoadingOverlay();
  //return(false);
  this.modelController.create({component:FileUploadErrorPage,backdropDismiss:false,cssClass: 'fileuploadError-modal'}).then((modalElement)=>{
    modalElement.present();
  });
}



/* ORIGINAL OLD CODE
                      if ( this.uploading_File_from == 'main_upload_page'){
                        this.fileUploadResponse=data;
                        // SCLTD // console.log(this.fileUploadResponse);
                        this.populate_vars_after_fileUpload();
                        this.HideLoadingOverlay();
                        //return(true);
                        this.router.navigate(['/custom-quote/pcb-details']);
                      }
                      if ( this.uploading_File_from == 'issues_upload_page'){
                        // window.scrollTo(0, 0);
                        this.fileUploadResponse=data;
                        // SCLTD // console.log(this.fileUploadResponse);
                        this.populate_vars_after_fileUpload();
                        this.HideLoadingOverlay();
                        //document.body.scrollTop = document.documentElement.scrollTop = 0;
                        // $('body').scrollTop(0);
                        // this.content.scrollToTop(1500);

                        var scrollElem= document.querySelector('#moveTop');
                        scrollElem.scrollIntoView();

                        //this.router.navigate(['/custom-quote/issues-conf-step1']);

                        //return(true);
                        // this.router.navigate(['/custom-quote/pcb-details']);
                      }
ORIGINAL OLD CODE */

                     }, error => {
                      // SCLTD // console.log(error);
                      if ( this.uploading_File_from == 'main_upload_page'){
                        this.fileUploadResponse = null;
                        this.populate_vars_after_fileUpload();
                        // SCLTD // console.log(error);
                        // alert("error");
                        this.HideLoadingOverlay();
                        //return(false);
                        this.modelController.create({component:FileUploadErrorPage,backdropDismiss:false,cssClass: 'fileuploadError-modal'}).then((modalElement)=>{
                          modalElement.present();
                        });
                      }
                      if ( this.uploading_File_from == 'issues_upload_page'){
                        // window.scrollTo(0, 0);
                        this.fileUploadResponse = null;
                        this.populate_vars_after_fileUpload();
                        // SCLTD // console.log(error);
                        // alert("error");
                        this.HideLoadingOverlay();
                        //document.body.scrollTop = document.documentElement.scrollTop = 0;
                        // $('body').scrollTop(0);

                        // var scrollElem= document.querySelector('#moveTop');
                        // scrollElem.scrollIntoView();

                        //return(false);
                        this.modelController.create({component:FileUploadErrorPage,backdropDismiss:false,cssClass: 'fileuploadError-modal'}).then((modalElement)=>{
                          modalElement.present();
                        });
                      }

                    });

  }

  reset_glb_variables(){
    // this.fupResponse_block_user_flag="";
    // this.fupResponse_block_user_msgs="";

    // SCLTD // console.log("reset_glb_variables");
    this.reset_variables_check = true;

    this.usr_quote_type_selected="";
    //this.file2Upload=null;

    // let file2Upload: File;
    // document.getElementById("f_1").value=null;
    this.frm_additional_notes="";
    this.frm_gen_notes_4_SierrTeam="";
    this.frm_submt_dsgPack_withErrors_Confirm = false;
    this.fup_error_msgs_text = "";
    this.frm_qsummary_contact_email_id = "";
    this.frm_Design_Package_Name = "";
    this.frm_part_no ="";
    this.frm_revision ="";
    this.frm_mil_spec="Not Sure";
    this.frm_itar="Not Sure";
    this.frm_n_layers ="1";
    this.frm_board_dim_x="";
    this.frm_board_dim_y="";
    this.frm_board_thickness="";
    this.frm_surface_finish="";
    this.frm_board_material="";
    this.frm_bom_file_name="";
    // this.frm_tot_no_of_components="";
    // this.frm_do_not_install="";

    this.frm_noof_boards=1;
    this.frm_noof_assemblies=1;
    this.frm_noof_bare_boards=this.frm_noof_boards - this.frm_noof_assemblies;
    this.frm_turn_time_days="1 Day";
    this.frm_turn_time_days_assmb="1 Day";
  }

  populate_vars_after_fileUpload(){

    if ( this.fileUploadResponse==null){
        this.showErrorBlock="block";
        this.showSuccessBlock="none";
    } else {
        if ( this.fileUploadResponse!=null &&
          this.fileUploadResponse!="" &&
          this.fileUploadResponse.errorMessages!=null &&
          this.fileUploadResponse.errorMessages!="" &&
          this.fileUploadResponse.errorMessages.length >0 ){
            this.showErrorBlock="block";
            this.showSuccessBlock="none";
          } else {
            this.showErrorBlock="none";
            this.showSuccessBlock="block";
          }
    }

    if (this.fileUploadResponse!=null &&
      this.fileUploadResponse!=""){
          // this.fup_error_msgs_text = "";
          // this.frm_qsummary_contact_email_id = "";
          // this.frm_Design_Package_Name = "";
          // this.frm_part_no ="";
          // this.frm_revision ="";
          // this.frm_mil_spec="Not Sure";
          // this.frm_itar="Not Sure";
          // this.frm_n_layers ="1";
          // this.frm_board_dim_x="";
          // this.frm_board_dim_y="";
          // this.frm_board_thickness="";
          // this.frm_surface_finish="";
          // this.frm_board_material="";
          // this.frm_bom_file_name="";
          // // this.frm_tot_no_of_components="";
          // // this.frm_do_not_install="";

          // this.frm_noof_boards=1;
          // this.frm_noof_assemblies=1;
          // this.frm_noof_bare_boards=this.frm_noof_boards - this.frm_noof_assemblies;
          // this.frm_turn_time_days="1 Day";

          // this.reset_glb_variables();

          // form data values checking
          if ( this.fileUploadResponse.partNumber!=null &&
            this.fileUploadResponse.partNumber!="" ){
              this.frm_part_no = this.fileUploadResponse.partNumber;
          } else {
            //++//this.frm_part_no = "";
          }

          if ( this.fileUploadResponse.revision!=null &&
            this.fileUploadResponse.revision!="" ){
              this.frm_revision = this.fileUploadResponse.revision;
          } else {
            //++//this.frm_revision = "";
          }

          if ( this.fileUploadResponse.noOfCuLay!=null &&
            this.fileUploadResponse.noOfCuLay!="" ){
              this.frm_n_layers = this.fileUploadResponse.noOfCuLay;
          } else {
            //++//this.frm_n_layers = "1";
          }

          if ( this.fileUploadResponse["dimension-x"]!=null &&
            this.fileUploadResponse["dimension-x"]!="" ){
              this.frm_board_dim_x = this.fileUploadResponse["dimension-x"];
              this.frm_board_dim_x =""+parseFloat(this.frm_board_dim_x).toFixed(3);
          } else {
            //++//this.frm_board_dim_x = "";
          }
          if ( this.fileUploadResponse["dimension-y"]!=null &&
            this.fileUploadResponse["dimension-y"]!="" ){
              this.frm_board_dim_y = this.fileUploadResponse["dimension-y"];
              this.frm_board_dim_y =""+parseFloat(this.frm_board_dim_y).toFixed(3);
          } else {
            //++//this.frm_board_dim_y = "";
          }

          if ( this.fileUploadResponse.emailAddress!=null &&
            this.fileUploadResponse.emailAddress!="" ){
              this.frm_qsummary_contact_email_id = this.fileUploadResponse.emailAddress;
          } else {
            //++//this.frm_qsummary_contact_email_id = "";
          }

          // error messages
          var errm;
          if ( this.fileUploadResponse!=null &&
            this.fileUploadResponse!="" &&
            this.fileUploadResponse.errorMessages!=null &&
            this.fileUploadResponse.errorMessages!="" &&
            this.fileUploadResponse.errorMessages.length >0 ){
              errm=this.fileUploadResponse.errorMessages;
              for (let i = 0; i < errm.length; i++) {
                  if ( i == 0 ){
                    this.fup_error_msgs_text = errm[i];
                  } else {
                    this.fup_error_msgs_text = this.fup_error_msgs_text +", "+ errm[i];
                  }
              }
            } else {
              errm=null;
              this.fup_error_msgs_text = "";
            }
          // error messages
    } else {
      this.reset_glb_variables();

      // this.fup_error_msgs_text = "";
      // this.frm_qsummary_contact_email_id = "";
      // this.frm_Design_Package_Name = "";
      // this.frm_part_no ="";
      // this.frm_revision ="";
      // this.frm_mil_spec="Not Sure";
      // this.frm_itar="Not Sure";
      // this.frm_n_layers ="1";
      // this.frm_board_dim_x="";
      // this.frm_board_dim_y="";
      // this.frm_board_thickness="";
      // this.frm_surface_finish="";
      // this.frm_board_material="";
      // this.frm_bom_file_name="";
      // // this.frm_tot_no_of_components="";
      // // this.frm_do_not_install="";

      // this.frm_noof_boards=1;
      // this.frm_noof_assemblies=1;
      // this.frm_noof_bare_boards=this.frm_noof_boards - this.frm_noof_assemblies;
      // this.frm_turn_time_days="1 Day";
    }

    this.fetch_pcb_front_image();
    this.fetch_pcb_bottom_image();
  }

  fetch_pcb_front_image(){
    if (this.fileUploadResponse!=null &&
      this.fileUploadResponse!="" &&
      this.fileUploadResponse.topImage!=null &&
      this.fileUploadResponse.topImage!="" ){

    var headers = new  HttpHeaders().set("Authorization", this.valid_user.access_token)
                      .set("user_id", this.sso_UserId )
                      .set("Accept", "*/*" );
// this.valid_user.user_id
    let params = new HttpParams().set("service", "getFileBase64")
                  .set("fileName", this.fileUploadResponse.topImage);

    var options = { headers: headers, params: params};
      this.httpClient.get<any>( Configuration.BaseAppProtoexpressUrl_pcb_front_image ,  options
      ).subscribe(data => {
        this.pcb_front_image_data = data;
        this.pcb_front_image_data_img = this.sanitizer.bypassSecurityTrustResourceUrl('data:image/png;base64,'+ (data.body).split("'")[1] );

        // console.log(this.pcb_front_image_data);
        // console.log(this.pcb_front_image_data_img);

        // this.populate_vars_after_fileUpload();
        // this.HideLoadingOverlay();
        // this.router.navigate(['/custom-quote/pcb-details']);
      }, error => {
        this.pcb_front_image_data = "";
        this.pcb_front_image_data_img = null;
        // SCLTD // console.log("ERROR: No pcb front image data");
        // console.log(error);
      });

    } // this.fileUploadResponse!=null
  }

  fetch_pcb_bottom_image(){
    if (this.fileUploadResponse!=null &&
      this.fileUploadResponse!="" &&
      this.fileUploadResponse.botImage!=null &&
      this.fileUploadResponse.botImage!="" ){

    var headers = new  HttpHeaders().set("Authorization", this.valid_user.access_token)
                      .set("user_id", this.sso_UserId )
                      .set("Accept", "*/*" );
// this.valid_user.user_id
    let params = new HttpParams().set("service", "getFileBase64")
                  .set("fileName", this.fileUploadResponse.botImage);

    var options = { headers: headers, params: params};
      this.httpClient.get<any>( Configuration.BaseAppProtoexpressUrl_pcb_bottom_image ,  options
      ).subscribe(data => {
        this.pcb_bottom_image_data = data;
        this.pcb_bottom_image_data_img = this.sanitizer.bypassSecurityTrustResourceUrl('data:image/png;base64,'+ (data.body).split("'")[1] );

        // console.log(this.pcb_bottom_image_data);
        // console.log(this.pcb_bottom_image_data_img);

        // this.populate_vars_after_fileUpload();
        // this.HideLoadingOverlay();
        // this.router.navigate(['/custom-quote/pcb-details']);
      }, error => {
        this.pcb_bottom_image_data = "";
        this.pcb_bottom_image_data_img = null;
        //console.log("ERROR: No pcb bottom image data");
        // console.log(error);
      });

    } // this.fileUploadResponse!=null
  }

  ClosePopUpModal(){
    this.modelController.dismiss();
  }

  ShowIssuesPopUpModal(){

    this.modelController.create({component:IssuesInfoPage,backdropDismiss:false,cssClass: 'issuesInfoPage-modal'}).then((modalElement)=>{
      modalElement.present();
    });

  }

  GetLoggedInUserDetails(){
    this.ShowLoadingOverlay("Fetching User Details...");

    var headers  = new  HttpHeaders().set("Authorization", "Bearer "+this.valid_user.access_token)
                      .set("Content-Type", "application/json" )
                      .set("Accept", "application/json" );

    let params  = new HttpParams().set("user_id", this.valid_user.user_id);

    //var options = { headers: headers, params: params};

    this.httpClient.get<any>( Configuration.BaseApiProtoexpressUrl
      , {headers: headers , params: params }
      ).subscribe((data: any[])=>{
        this.valid_user_details = data;
        // SCLTD // console.log(this.valid_user_details);
        this.router.navigate(['/custom-quote/upload-file']);
        this.HideLoadingOverlay();
      }, (error: any[])=>{
        // SCLTD // console.log(error);
        this.valid_user=null;
        this.valid_user_details = null;
        this.HideLoadingOverlay();
        this.invalid_login = true;
      });

  }

  // +++++++++++++++++++++++++++++++++++++++++++++++
  Final_Quote_Submit_STEP_1_SIVA(){
    this.ShowLoadingOverlay("Submitting Quote. Please Wait...");

    let formData = new FormData();
    formData.append("username",Configuration.FS_BaseApiUrl_username);
    formData.append("password", Configuration.FS_BaseApiUrl_password );
    formData.append("grant_type",Configuration.FS_BaseApiUrl_grant_type);

    var headers = new  HttpHeaders().set("Authorization", Configuration.FS_BaseApiUrl_Authorization)
                      .set("Accept", "*/*" )
                      .set("Content-Type", "application/x-www-form-urlencoded" );

    var options = { headers: headers};
    this.httpClient.post<any>( Configuration.FS_BaseApiUrl, formData, options
                    ).subscribe(data => {
                        this.FS_Step1_resp=data;
                        // SCLTD // console.log(this.FS_Step1_resp);

                        this.HideLoadingOverlay();

                        this.Final_Quote_Submit_STEP_2();

                        //return(true);
                     }, error => {
                        this.FS_Step1_resp = null;

                        // SCLTD // console.log(error);
                        this.HideLoadingOverlay();
                        // this.modelController.create({component:FileUploadErrorPage,backdropDismiss:false,cssClass: 'fileuploadError-modal'}).then((modalElement)=>{
                        //   modalElement.present();
                        // });
                    });
  }

  Final_Quote_Submit_STEP_1(){
    // this.modelController.create({component:FinalSubmitSuccessPage,backdropDismiss:false,cssClass: 'FinalSubmitSuccessPage-modal'}).then((modalElement)=>{
    //                       modalElement.present();
    //                     });

    // this.modelController.create({component:FinalSubmitErrorPage,backdropDismiss:false,cssClass: 'FinalSubmitErrorPage-modal'}).then((modalElement)=>{
    //   modalElement.present();
    // });

    // return;

    this.ShowLoadingOverlay("Submitting Quote request. Please Wait...");

    var headers = new HttpHeaders().set("Authorization", Configuration.FS_BaseApiUrl_Authorization )
    .set("Content-Type", "application/x-www-form-urlencoded" )
    .set("Accept", "application/json" )
    .set("Access-Control-Allow-Origin", "*" )
    .set("Access-Control-Allow-Methods", "GET" )
    .set("Access-Control-Allow-Methods", "PUT" )
    .set("Access-Control-Allow-Methods", "POST" )
    .set("Access-Control-Allow-Methods", "DELETE" )
    .set("Access-Control-Allow-Methods", "OPTIONS" )
    .set("Access-Control-Allow-Methods", "POST" )
    .set("Access-Control-Allow-Headers", "X-Requested-With" );
const body = new HttpParams()
.set('username', Configuration.FS_BaseApiUrl_username)
.set('password', Configuration.FS_BaseApiUrl_password)
.set('grant_type', Configuration.FS_BaseApiUrl_grant_type);
return this.httpClient.post<any>( Configuration.FS_BaseApiUrl
                    , body
                    , {headers}
                    ).subscribe((data)=>{

                        this.FS_Step1_resp=data;
                        // SCLTD // console.log(this.FS_Step1_resp);

                        this.HideLoadingOverlay();

                        this.Final_Quote_Submit_STEP_2();

                        //return(true);

                    }, (error: any[])=>{

                        this.FS_Step1_resp = null;

                        // SCLTD // console.log(error);
                        this.HideLoadingOverlay();

                        this.modelController.create({component:FinalSubmitErrorPage,backdropDismiss:false,cssClass: 'FinalSubmitErrorPage-modal'}).then((modalElement)=>{
                          modalElement.present();
                        });

                    });

  }

  Final_Quote_Submit_STEP_2(){
    this.ShowLoadingOverlay("Submitting Quote request. Please Wait...");

    var headers = new  HttpHeaders().set("Authorization", "bearer "+this.FS_Step1_resp.access_token)
                      .set("Content-Type", "application/json" )
                      .set("Accept", "application/json" );

    var itar1 = ((this.frm_itar=="Yes")?"1":"0");

    // var postData = {
    //                 "assemblyLaborCheck": "0",
    //                 "componentsCheck": "0",
    //                 "designCheck": "0",
    //                 "email": this.frm_qsummary_contact_email_id,
    //                 "itar": itar1,
    //                 "noFly": "0",
    //                 "partNumber": this.frm_part_no,
    //                 "pcbFabCheck": "1",
    //                 "pcbQuantity1": "2",
    //                 "pcbQuantity2": "5",
    //                 "requestOwner": "techsupport"
    //               };

    var wamid = "";
    if (this.valid_user_details!=null && this.valid_user_details.webAccountManagerId !=null
      && this.valid_user_details.webAccountManagerId.length > 0 ){
      wamid = this.valid_user_details.webAccountManagerId;
    }

    var alc = "0";

    if (this.usr_quote_type_selected!=null
      && this.usr_quote_type_selected!=""
      && this.usr_quote_type_selected=="Fabrication"){
             alc = "0";
    }

    if (this.usr_quote_type_selected!=null
      && this.usr_quote_type_selected!=""
      && this.usr_quote_type_selected=="Fabrication,Assembly"){
             alc = "1";
    }

    var tt_pcb = "";
    var tt_ass = "";

    if (this.usr_quote_type_selected!=null
      && this.usr_quote_type_selected!=""
      && this.usr_quote_type_selected=="Fabrication"){
        tt_pcb=this.frm_turn_time_days;
        tt_ass = "";
      }
      if (this.usr_quote_type_selected!=null
        && this.usr_quote_type_selected!=""
        && this.usr_quote_type_selected=="Fabrication,Assembly"){
          tt_pcb=this.frm_turn_time_days;
          tt_ass = this.frm_turn_time_days_assmb;
        }

    var postData = {
        "assemblyLaborCheck": alc,
        "componentsCheck": "0",
        "designCheck": "0",
        "email": this.frm_qsummary_contact_email_id,
        "itar": itar1,
        "noFly": "0",
        "partNumber": this.frm_part_no,
        "partRev": this.frm_revision,
        "pcbFabCheck": "1",
        "requestOwner": wamid,
        "assemblyQuntity1": this.frm_noof_assemblies,
        "assemblyQuntity2": "",
        "assemblyTurnTime1": tt_ass,
        "assemblyTurnTime2": "",
        "pcbQuantity1": this.frm_noof_boards,
        "pcbQuantity2": "",
        "pcbTurnTime1": tt_pcb,
        "pcbTurnTime2": ""
      };

    let params = new HttpParams().set("userID", this.sso_UserId );
// this.valid_user.user_id
    var options = { headers: headers, params: params};

    this.httpClient.post<any>( Configuration.FS_CusQuoApiUrl, postData, options
                    ).subscribe(data => {

                      this.FS_Step2_resp=data;
                      // SCLTD // console.log(this.FS_Step2_resp);

                      this.HideLoadingOverlay();

                      this.Final_Quote_Submit_STEP_3();
                      //return(true);

                     }, error => {

                      this.FS_Step2_resp = null;

                      // SCLTD // console.log(error);
                      this.HideLoadingOverlay();

                      this.modelController.create({component:FinalSubmitErrorPage,backdropDismiss:false,cssClass: 'FinalSubmitErrorPage-modal'}).then((modalElement)=>{
                        modalElement.present();
                      });

                    });
  }

  Final_Quote_Submit_STEP_3(){
    this.ShowLoadingOverlay("Submitting Quote request. Please Wait...");

    var headers = new  HttpHeaders().set("Authorization", "bearer "+this.FS_Step1_resp.access_token)
    .set("Content-Type", "application/x-www-form-urlencoded" )
    .set("Accept", "application/json" )
    .set("Access-Control-Allow-Origin", "*" )
    .set("Access-Control-Allow-Methods", "GET" )
    .set("Access-Control-Allow-Methods", "PUT" )
    .set("Access-Control-Allow-Methods", "POST" )
    .set("Access-Control-Allow-Methods", "DELETE" )
    .set("Access-Control-Allow-Methods", "OPTIONS" )
    .set("Access-Control-Allow-Methods", "POST" )
    .set("Access-Control-Allow-Headers", "X-Requested-With" );

    let formData = new HttpParams()
        .set("femsRnumber",this.FS_Step2_resp.requestNumber)
        .set("projectId", this.fileUploadResponse.projectId )
        .set("version",this.fileUploadResponse.version )
        .set("userId",this.sso_UserId )
        .set("source","custom_quote" );
//this.valid_user.user_id
    var options = { headers: headers};
    this.httpClient.post<any>( Configuration.FS_SubCustOrderApiUrl, formData, options
                    ).subscribe(data => {
                      // console.log("STEP 3");
                      // SCLTD // console.log(data);
                        this.FS_Step3_resp=data;
                        // SCLTD // console.log(this.FS_Step3_resp);

                        this.HideLoadingOverlay();

                        this.Final_Quote_Submit_STEP_4();

                        //return(true);
                     }, error => {
                        this.FS_Step3_resp = null;

                        // SCLTD // console.log(error);
                        this.HideLoadingOverlay();

                        this.modelController.create({component:FinalSubmitErrorPage,backdropDismiss:false,cssClass: 'FinalSubmitErrorPage-modal'}).then((modalElement)=>{
                          modalElement.present();
                        });

                    });
  }
  // +++++++++++++++++++++++++++++++++++++++++++++++

  Final_Quote_Submit_STEP_4(){
    this.ShowLoadingOverlay("Submitting Quote request. Please Wait...");
    //FS_femsgetpdlistUrl

    var headers = new  HttpHeaders().set("Authorization", "bearer "+this.FS_Step1_resp.access_token)
                      .set("Content-Type", "application/json" )
                      .set("Accept", "application/json" );

    let params = new HttpParams().set("userID", this.sso_UserId )
                  .set("requestNumber", this.FS_Step2_resp.requestNumber);
// this.valid_user.user_id
    var options = { headers: headers, params: params};

    this.httpClient.get<any>( Configuration.FS_femsgetpdlistUrl
      , {headers: headers , params: params }
      ).subscribe((data: any[])=>{

        this.FS_Step4_resp=data;
        // SCLTD // console.log(this.FS_Step4_resp);

        this.HideLoadingOverlay();

        this.Final_Quote_Submit_STEP_5();

      }, (error: any[])=>{

        this.FS_Step4_resp = null;

        // SCLTD // console.log(error);
        this.HideLoadingOverlay();

        this.modelController.create({component:FinalSubmitErrorPage,backdropDismiss:false,cssClass: 'FinalSubmitErrorPage-modal'}).then((modalElement)=>{
          modalElement.present();
        });

      });
  }

  Final_Quote_Submit_STEP_5(){
    this.ShowLoadingOverlay("Submitting Quote request. Please Wait...");

    // var headers = new  HttpHeaders().set("Authorization", "bearer "+this.FS_Step1_resp.access_token)
    //                   .set("Content-Type", "application/json" )
    //                   .set("Accept", "application/json" );

    //.set("Content-Type", "application/x-www-form-urlencoded" )
    // .set("Content-Type", "application/json" )
    // .set("Accept", "*/*" )

    var headers = new HttpHeaders().set("Authorization", "bearer "+this.FS_Step1_resp.access_token)
    .set("Content-Type", "application/json" )
    .set("Accept", "*/*" )
    .set("Access-Control-Allow-Origin", "*" )
    .set("Access-Control-Allow-Methods", "GET" )
    .set("Access-Control-Allow-Methods", "PUT" )
    .set("Access-Control-Allow-Methods", "POST" )
    .set("Access-Control-Allow-Methods", "DELETE" )
    .set("Access-Control-Allow-Methods", "OPTIONS" )
    .set("Access-Control-Allow-Methods", "POST" )
    .set("Access-Control-Allow-Headers", "X-Requested-With" );

    var itar = ((this.frm_itar=="Yes")?"1":"0");
    var enl_test = ((this.frm_include_electrical_testing==true)?"1":"0");
    var dms = ((this.frm_mil_spec=="Yes")?"1":"0");
    var qss="";
    if ( this.fup_error_msgs_text == "" || this.fup_error_msgs_text == null ){
      qss = "No Issues";
    } else {
      if ( this.frm_submt_dsgPack_withErrors_Confirm == true){
        qss = "Accepted with Issues";
      } else {
        qss = "No Issues";
      }
    }

    var tt_pcb = "";
    var tt_ass = "";

    if (this.usr_quote_type_selected!=null
      && this.usr_quote_type_selected!=""
      && this.usr_quote_type_selected=="Fabrication"){
        tt_pcb=this.frm_turn_time_days;
        tt_ass = "";
      }
      if (this.usr_quote_type_selected!=null
        && this.usr_quote_type_selected!=""
        && this.usr_quote_type_selected=="Fabrication,Assembly"){
          tt_pcb=this.frm_turn_time_days;
          tt_ass = this.frm_turn_time_days_assmb;
        }

        //"turntime": this.frm_turn_time_days,
        //"assemblyTurnTime1": tt_ass,
        //"pcbTurnTime1": tt_pcb

     var postData =  { "additionalNotesFromCustomer": this.frm_additional_notes,
     "boardMaterial": this.frm_board_material,
     "boardThickness": this.frm_board_thickness,
     "bomFileName": this.frm_bom_file_name,
     "isDesignMilSpec": dms,
     "isElectricalNetListTesting": enl_test,
     "isFlyingProbeTesting": "0",
     "issuesInDesignPackage": this.fup_error_msgs_text,
     "itar": itar,
     "layers": this.frm_n_layers,
     "mail": this.frm_qsummary_contact_email_id,
     "noOfAssemblyBoard": this.frm_noof_assemblies,
     "noOfBareBoard": this.frm_noof_bare_boards,
     "noOfBoards": this.frm_noof_boards,
     "noOfComponents": "0",
     "noOfDniComponents": "0",
     "notesFromCustomer": this.frm_gen_notes_4_SierrTeam,
     "partNumber": this.frm_part_no,
     "quoteSubmissionStatus": qss,
     "requestId": this.FS_Step2_resp.requestNumber ,
     "partDataId": this.FS_Step4_resp[0] ,
     "revision": this.frm_revision,
     "surfaceFinish": this.frm_surface_finish,
     "xDimension": this.frm_board_dim_x,
     "yDimension": this.frm_board_dim_y,
     "assemblyTurnTime": tt_ass,
     "pcbTurntime": tt_pcb               }   ;

     let params = new HttpParams().set("userID", this.sso_UserId );
// this.valid_user.user_id
        //var options = { headers: headers, params: params };
        const options = { headers: headers, params: params , responseType: 'text' as 'text'};


        this.httpClient.post( Configuration.FS_femscreateprepartdataUrl, JSON.stringify( postData ), options
                    ).subscribe(data => {
                      // console.log("YES");
                      this.FS_Step5_resp=data;
                      // SCLTD // console.log(this.FS_Step5_resp);

                      this.HideLoadingOverlay();
                      //return(true);

                      this.modelController.create({component:FinalSubmitSuccessPage,backdropDismiss:false,cssClass: 'FinalSubmitSuccessPage-modal'}).then((modalElement)=>{
                        modalElement.present();
                      });

                      this.reset_glb_variables();

                     }, error => {
                      // console.log("NO");
                      this.FS_Step5_resp = null;

                      // SCLTD // console.log(error);
                      this.HideLoadingOverlay();

                      this.modelController.create({component:FinalSubmitErrorPage,backdropDismiss:false,cssClass: 'FinalSubmitErrorPage-modal'}).then((modalElement)=>{
                        modalElement.present();
                      });

                    });
  }

}
