import { Component, OnInit } from '@angular/core';
import { CommonService } from '../../../services/common.service';
import { Router } from '@angular/router';

@Component({
  selector: 'app-file-upload-block-user',
  templateUrl: './file-upload-block-user.page.html',
  styleUrls: ['./file-upload-block-user.page.scss'],
})
export class FileUploadBlockUserPage implements OnInit {

  public blockMessages;

  constructor(public commonService: CommonService, private router: Router) { }

  ngOnInit() {
    window.addEventListener("keyup", disableF5);
    window.addEventListener("keydown", disableF5);
                function disableF5(e) {
                  // if ((e.which || e.keyCode) == 116) e.preventDefault();
                  if ((e.which || e.keyCode) == 116){ e.preventDefault();   };
              };
  }



  ionViewDidEnter() { //ngAfterViewInit(){

    if (this.commonService.valid_user!=null){

    } else {
      // this.router.navigate(['/home']);
      this.router.navigate(['/']);
    }

      // NGON INIT ------------------------------------------------
      window.addEventListener("keyup", disableF5);
      window.addEventListener("keydown", disableF5);

        function disableF5(e) {
          // if ((e.which || e.keyCode) == 116) e.preventDefault();
          if ((e.which || e.keyCode) == 116){ e.preventDefault();   };
      };

      this.blockMessages=this.commonService.fupResponse_block_user_msgs;

      // NGON INIT ------------------------------------------------

  }

  close_clicked(){
    this.commonService.ClosePopUpModal();
  }

  closeDialog(){
    this.commonService.ClosePopUpModal();
  }

}
